<template>
    <section class="obras-vigentes-ordenes-compra container-fluid overflow-auto scroll-none">
        <titulo-divisor titulo="Orden de compra">
            <div class="row">
                <div class="col auto">
                    <el-input v-model="buscarListado" placeholder="Buscar orden" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">3</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4" @click="createMatrizOc">Crear orden</button>
                </div>
          </div>
        </titulo-divisor>
        <div class="row my-4 justify-content-end">
            <div class="col-auto">
                <div class="d-middle">
                    <i class="icon-progress-pencil text-5d f-16" />
                    <p class="f-12">Borrador</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle">
                    <i class="icon-history text-orange f-16" />
                    <p class="f-12">Pendiente</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle">
                    <i class="icon-circle-check text-info2 f-16" />
                    <p class="f-12">Entrega parcial</p>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-middle">
                    <i class="icon-circle-check text-verde f-16" />
                    <p class="f-12">Entrega completa</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 shadow29 border br-4 px-0">
                <el-table :data="listaOrdenCompra.data" style="width: 100%" max-height="450">
                    <el-table-column fixed align="center" prop="id" width="115" label="Ref.">
                        <template slot-scope="scope">
                            {{ scope.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="nombre" label="Nombre" width="250">
                        <template slot-scope="scope">
                            <p class="tres-puntos">{{ scope.row.nombre }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="tipo_material" label="Tipo" width="165">
                        <template slot-scope="scope">
                            Materiales
                        </template>
                    </el-table-column>
                    <el-table-column fixed align="center" prop="total_unidades" width="90" label="Total de unidades">
                        <template slot-scope="scope">
                            {{ scope.row.total_unidades }}
                        </template>
                    </el-table-column>
                    <div v-for="(column, index) in ocs" :key="index">
                        <el-table-column prop="orden1" min-width="150" header-align="center">
                            <template slot="header">
                                <el-tooltip placement="bottom" effect="light" visible-arrow>
                                    <div slot="content" class="text-center">
                                        <p class="f-12 text-muted">Proveedor: {{column.proveedor}}</p>
                                        <p class="f-12 text-muted">fecha: {{column.fecha}}</p>
                                        <p class="f-12 text-muted">flete: {{column.flete}}</p>
                                        <p class="f-12 text-muted">bodega: {{column.bodega}}</p>
                                    </div>
                                    <div class="d-middle-center">
                                        <i class="f-18" :class="getIcon(column.estado)" />
                                        <p class="cr-pointer" @click="abrirModalInfoCompras(column.id)">Orden {{column.id}}</p>
                                    </div>
                                </el-tooltip>
                            </template>
                            <template slot-scope="scope">
                                <div class="text-center">
                                    <!-- <p>{{inputVal(scope.row.ordenes_compra, column, scope.$index, index)}}</p> -->
                                    <el-input-number 
                                        v-model="scope.row.ordenes_compra[index].v_model"
                                        :controls="false" @change="actualizarCantidad($event, scope.row, column)" :min="0"
                                        :disabled="scope.row.ordenes_compra[index].disabled"
                                        class="w-50 mx-auto text-center"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="valueSelect" placeholder="Seleccionar tipo material" size="small" class="w-100">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-info-compra ref="abrirModalInfoCompras" />
        <modal-create-matriz ref="createMatrizOc" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalCreateMatriz from '~/pages/compras/partials/modalCreateMatriz.vue'
import modalInfoCompra from './partials/modalInfoCompra.vue'
export default {
    components:{
        modalCreateMatriz,
        modalInfoCompra
    },
    data(){
        return{
            buscarListado: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            cantFiltros: 0,
            checkEstados: [],
            radioTipoRevision: '1',
            tableData:[
                {
                    id: 1,
                    referencia: '0001',
                    nombre: 'Plancha de acero al carbono de baja resistencia',
                    type: 'Metal',
                    totalUnidades: 15,
                    unidadesAsignadas: 10,
                    orden1: 5,
                    orden2: 2,
                    orden3: 3,
                    orden4: 4,
                },
            ],

            id_proyecto: this.$route.params.id,
            ocs:[]

            //ICONOS
            // <i class="icon-progress-pencil text-5d f-18" />
            // <i class="icon-history text-orange f-18" />
            // <i class="icon-circle-check text-info2 f-18" />
            // <i class="icon-circle-check text-verde f-18" />
        }
    },
    computed: {
        ...mapGetters({
            informacion: 'obras/informacion/informacion',
            listaOrdenCompra: 'obras/ordenesCompras/listaOrdenCompra'
        })
    },
    async created () {
        await this.listar();
        this.getSelects(['Action_get_select_monedas'])
    },
    methods:{
        ...mapActions({
            Action_get_lista_orden_compra: 'obras/ordenesCompras/Action_get_lista_orden_compra',
            Action_get_orden_compra: 'obras/ordenesCompras/Action_get_orden_compra',
            getSelects: 'selects/selects/sync'
        }),
        async listar(){
            await this.Action_get_lista_orden_compra({id_proyecto: this.id_proyecto})
            this.listaOcs()
        },
        inputVal(ocs, data, indexCol, indexMaterial){
            // console.log({ocs, data, indexCol, indexMaterial});
            // let retu = ocs.find(el=> el.id == data)
            // this.listaOrdenCompra[indexCol].ordenes_compra.map(el => ({ ...el, vmodel: retu?.cantidad ?? '0' }) )

            // TENOMS
            //  ID OCS || OCS del motarial || index de la column || index del material
            // return retu.cantidad
            return 2
        },
        actualizarCantidad(val, material, ordenCompra){
            console.log({val, material, ordenCompra});
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
		abrirModalInfoCompras(id){
			this.$refs.abrirModalInfoCompras.toggle(id)
		},
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        filtrarMateriales(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 1
        },
        limpiarFiltro(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 0
        },
        createMatrizOc(){
            const oportunidad = {
                id : this.informacion?.id_oportunidad ?? 0,
                nombre : this.informacion?.nombre ?? "",
            }
            this.$refs.createMatrizOc.ocsOps(oportunidad)
        },
        listaOcs(){
            this.ocs = this.listaOrdenCompra?.data[0]?.ordenes_compra ?? []
          

            console.table(this.ocs);
        },
        getIcon(estado){
            switch (estado) {
                case 1:
                    return "icon-progress-pencil text-5d"
                    break;
                case 2:
                    return "icon-history text-orange"
                    break;
                case 3:
                    return "icon-circle-check text-info2"
                    break;
                case 4:
                    return "icon-circle-check text-verde"
                    break;
            
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.obras-vigentes-ordenes-compra{
    height: calc(100vh - 415px);
}
</style>