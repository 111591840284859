<template>
    <modal ref="createOrdenCompra" titulo="Crear orden de compra" tamano="modal-lg">
        <div class="container-fluid">
            <ValidationObserver ref="validator">
                <section>
                    <div class="row">
                        <div class="col-3">
                            <ValidationProvider v-slot="{ errors }" name="fecha" rules="required">
                                <p class="input-label-top">Fecha de entrega</p>
                                <el-date-picker v-model="matriz.fecha" type="date" class="w-100" size="small" placeholder="Seleccionar una fecha" />
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <ValidationProvider v-slot="{ errors }" name="lugar" rules="required">
                                <p class="input-label-top">Lugar de entrega</p>
                                <el-input v-model="matriz.lugar" placeholder="Seleccionar lugar" size="small" class="w-100" />
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <ValidationProvider v-slot="{ errors }" name="valor" rules="required|numeric">
                                <p class="input-label-top">Valor flete</p>
                                <el-input v-model="matriz.flete" placeholder="Valor flete" size="small" class="w-100" />
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <ValidationProvider v-slot="{ errors }" name="moneda" rules="required">
                                <p class="input-label-top">Selecciona moneda</p>
                                <el-select v-model="matriz.id_moneda" placeholder="Seleccionar" size="small" class="w-100">
                                    <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                                </el-select>
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                        <div class="col-12 mt-3">
                            <ValidationProvider v-slot="{ errors }" name="descripción" rules="required|max:255">
                                <p class="input-label-top">Agregue descripción</p>
                                <el-input v-model="matriz.descripcion" show-word-limit :maxlength="255" type="textarea" :autosize="{ minRows: 4 }" placeholder="Descripción" size="small" class="w-100" />
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                    </div>
                </section>
                <!-- listado ops -->
                <section class="mt-4">
                    <div class="row justify-content-between border-bottom-g pb-2 mb-3">
                        <div class="col-auto my-auto">
                            <p class="text-general f-600 f-12">Elija la oportunidad</p>
                        </div>
                        <div class="col-auto my-auto" v-if="oportunidad == null">
                            <!-- <search :data-to-search="oportunidades" placeholder="Buscar oportunidad"  /> -->
                            <el-input v-model="searchOps" placeholder="Buscar oportunidad" size="small" clearable suffix-icon="el-icon-search" />
                        </div>
                    </div>
                    <div class="row" v-if="oportunidad == null">
                        <div class="col-12 overflow-auto custom-scroll" style="max-height: 190px;">
                            <ValidationProvider v-slot="{ errors }" name="oportunidad" rules="required" tag="div" class="row">
                                <el-radio  v-model="matriz.oportunidad" v-for="data in oportunidades.filter(el => (el.oportunidad.toLowerCase().includes(searchOps.toLowerCase())))" :key="data.id"  :label="data" class="col-5 mb-2 tres-puntos f-10 " @change="obtenerEquipo(data.id)">
                                    <el-tooltip placement="top">
                                        <div slot="content">
                                            {{ data.oportunidad }}
                                        </div>
                                        <span>
                                            {{ data.oportunidad }}
                                        </span>
                                    </el-tooltip>
                                </el-radio>
                                <vee-error :text="errors[0]" />
                            </ValidationProvider>
                        </div>
                    </div>
                        <div class="d-middle tres-puntos border col-lg-6 bg-db" v-else>
                            <p class="tres-puntos f-12 pr-2"> {{oportunidad.nombre}} </p>
                        </div>
                </section>
                <!-- listado product -->
                <section class="mt-4">
                    <div class="row justify-content-between border-bottom-g pb-2 mb-3">
                        <div class="col-auto my-auto">
                            <p class="text-general f-600 f-12">Elija el producto</p>
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="data in equipos" :key="data.id" class="col-auto mb-3">
                            <div class="card-product">
                                <p class="tres-puntos-2 lh-15 f-12 text-center"> {{ data.nombre }} </p>
                                <div
                                    class="`content wh-70 shadow29 br-5 d-middle-center mx-auto cr-pointer`"
                                    :class="`${model.productSelected.id === data.id && 'selected'} ${notAllowed(data.id)} `"
                                    @click="selectProduct(data);"
                                >
                                    <img :src="data.imagen | helper-storage" alt="" class="w-80 ">
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="show && equipos.length == 0" class="text-gray f-600 f-12 text-center"> "No hay equipos en esta oportunidad" </p>
                    <div class="row">
                        <div class="col">
                            <vee-error :text="error_equipo" />
                        </div>
                    </div>
                </section>
            </ValidationObserver>
        </div>
        <template slot="end-buttons">
            <button type="button" class="btn ml-1 mr-2 f-12 btn-general w-80px" @click="createMatrizOc"> Crear </button>
        </template>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { MatrizFactory } from '~/domain/entities/compras/matriz/factories/MatrizFactory'

export default {
    data(){
        return {
            options: [
                {
                    value: 1,
                    label: 'Lugar 1'
                },
                {
                    value: 2,
                    label: 'Lugar 2'
                },
                {
                    value: 3,
                    label: 'Lugar 3'
                },
                {
                    value: 4,
                    label: 'Lugar 4'
                },
                {
                    value: 5,
                    label: 'Lugar 5'
                }
            ],
            model:{
                productSelected: '',
            },
            show: false,
            matriz: MatrizFactory.instantiateEmpty(),
            searchOps: '',
            checkList:[],
            error_equipo: '',
            oportunidad:null,
            message: 'Not allowed'
        }
    },
    computed: {
        ...mapGetters({
            oportunidades: 'compras/ordenes_compra/oportunidades',
            select_monedas: 'selects/selects/select_monedas',
            equipos: 'compras/ordenes_compra/equipos',
        })
    },
    methods: {
        ...mapActions({
            obtenerOportunidades: 'compras/ordenes_compra/Action_get_oportunidades',
            obtenerEquipos: 'compras/ordenes_compra/Action_get_equipos',
        }),
        toggle(){
            this.cleanFields()
            this.obtenerOportunidades()
            this.$refs.validator.reset();
            this.$refs.createOrdenCompra.toggle()
        },
        async ocsOps(oportunidad){
            this.$refs.validator.reset()
            this.oportunidad = oportunidad
            this.matriz.oportunidad = oportunidad
            this.$refs.createOrdenCompra.toggle()
            await this.obtenerEquipos({id_oportunidad: oportunidad.id})
        },
        notAllowed(id){
            let etapa = false;
            let config = false;
            let material = false;
            this.equipos.forEach(el=>{
                if(el.id == id && el.alerta_etapa == 1) etapa = true
                if(el.id == id && el.alerta_config == 1) config = true
                if(el.id == id && el.alerta_material == 1) material = true
            })
            if (etapa) return 'content content-not-stages'
            if (config) return 'content content-not-settings'
            if (material) return 'content content-not-materials'
        },
        selectProduct(product){
            this.model.productSelected = product
            this.error_equipo = ''
        },
        async obtenerEquipo(id){
            await this.obtenerEquipos({id_oportunidad: id})
            this.show = true
        },
        async createMatrizOc (){
            let valid = await this.$refs.validator.validate();

            if(!this.model.productSelected && this.matriz.oportunidad) return this.error_equipo = 'Seleccione un equipo'

            if (valid) {
                this.$refs.createOrdenCompra.toggle()
                this.$refs.validator.reset();
                localStorage.setItem('orden_compra', JSON.stringify({
                    matriz: this.matriz,
                    producto: this.model.productSelected
                }))
                this.$router.push({ 
                        name: 'compras.crear.matriz.etapa.configurar',
                })
            }
        },
        cleanFields(){
            this.model = {
                productSelected: '',
            }
            this.matriz = MatrizFactory.instantiateEmpty()
        }
    }
}
</script>

<style lang="scss" scoped>
.card-product{
    min-width: 120px;
    max-width: 120px;
    .tres-puntos-2{
        min-height: 34px;
        max-height: 34px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .selected{
        background: #F6F6F6 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #00000038 !important;
    }
    .content{
        position: relative;
        &-not-stages{
            &:before{
                content: 'Sin etapas';
            }
        }
        &-not-settings{
            &:before{
                content: 'Sin configuración';
            }
        }
        &-not-materials{
            &:before{
                content: 'Sin materiales';
            }
        }
        &::before{
            transition: .2s ease-out;
            display: grid;
            place-items: center;
            text-align: center;
            font-size: 10px;
            font-weight: 600;
            color: white;
            background: #00000077;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
        }
        &:hover::before{
            transition: .3s ease-in;
            opacity: 1;
        }
    }
}
</style>
