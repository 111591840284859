<template>
    <modal ref="modalInfoCompra" titulo="Orden de compra">
		<template #end-buttons>
			<button
			type="button"
			class="btn ml-1 mr-2 f-12 btn-general f-300 w-auto"
			@click="goToOrder(buyList.id)"
			>Ir a la orden</button>
		</template>
		<div class="m-4 px-2 py-3">
			<div class="d-flex justify-content-between mb-1">
				<p class="ml-1 f-16 f-600">Orden de compra {{ buyList.id }}</p>
				<p class="f-14 f-600">{{ buyList.moneda }} {{ buyList.valor | currency('$', 2, { thousandsSeparator: '.' }) }}</p>
			</div>
			<div class="d-flex align-items-center">
				<i class="f-14" :class="getIcon(buyList.estado)" />
				<p class="mx-1 f-12 font-italic">{{ buyList.estadoNombre }} - {{ buyList.fechaFormateada }}</p>
			</div>
			<div class="d-flex">
				<p class="f-14 f-500">Proveedor: </p>
				<p class="f-14 ml-2 f-400"> {{ buyList.proveedor }}</p>
			</div>
			<div class="d-flex">
				<p class="f-14 f-500"> Destino:</p>
				<p class="f-14 ml-2 f-400"> {{ buyList.lugar }}</p>
			</div>
			<div class="d-flex">
				<p class="f-14 f-500"> Forma de pago:</p>
				<p class="f-14 ml-2 f-400"> {{ buyList.forma_pago }}</p>
			</div>
		</div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	data(){
        return {
            idOrden: '',
        }
    },
    computed: {
        ...mapGetters({
			buyList: 'obras/ordenesCompras/buyList'
        })
    },
    methods: {
		...mapActions({
			Action_get_orden_compra: 'obras/ordenesCompras/Action_get_orden_compra',
		}),
        async toggle(id){
			this.idOrden = id
			await this.listBuyData(this.idOrden)
            this.$refs.modalInfoCompra.toggle()
        },
		async listBuyData(id){
            await this.Action_get_orden_compra({ id_proyecto: this.$route.params.id, idOrdenesCompras:id })
		},
		goToOrder(id){
			this.$refs.modalInfoCompra.toggle()
			this.$router.push({ name: 'compras.ver.orden' , params: { id_orden : id } })
		},
		getIcon(estado){
            switch (estado) {
                case 1:
                    return "icon-progress-pencil text-5d"
                    break;
                case 2:
                    return "icon-history text-orange"
                    break;
                case 3:
                    return "icon-circle-check text-info2"
                    break;
                case 4:
                    return "icon-circle-check text-verde"
                    break;
                default:
                    break;
            }
        }
    }
}
</script>